import { Atom } from ":mods";
import { createSignal } from "solid-js";
import { OTPViewProps } from "../../models";
import { createOTPInputs } from "../../forms";

export function OTP({ onOTPSuccess }: OTPViewProps) {
  const FormOTP = createOTPInputs();
  const onDataChange = (otp: string) => {
    FormOTP.OTP.control.setValue(otp);
  };
  const onSubmit = async () => {
    const body = {
      otp: FormOTP.OTP.control.value,
    };

    if (!!FormOTP.OTP.control.value) {
     await onOTPSuccess(body)
    }
  };
  return (
    <section class="flex flex-col !font-rubik$ w-full h-full items-center justify-center absolute z-20 left-0 top-10%">
      <form class="flex flex-col text-center gap-40px !font-rubik$ w-500px h-fit bg#pt text#p rounded-lg p-40px ">
        <h1 class={`font-bold text-31px`}>Enter verification code</h1>
        <p class="text-16px font-normal ">
          we sent you an email with a verification code, please enter the code to continue.
        </p>
        <FormOTP.OTP.Input class="hidden" />
        <Atom.OTP.OTP onOTPChange={onDataChange} />

        <FormOTP.Actions.Button
          statusValid="Verify"
          statusInvalid="Verify"
          class="form-btn text-16px font-bold p-15px h-54px cursor-pointer"
          onclick={onSubmit}
        />
      </form>
    </section>
  );
}
